.sidebar {
    background: linear-gradient( -10deg, rgba(0,120,200,0.7), rgba(0,120,200,0.1) );
    -moz-box-shadow: 3px 3px 5px 1px #ccc;
    -webkit-box-shadow: 3px 3px 5px 1px #ccc;
    box-shadow: 3px 3px 5px 1px #ccc;
    min-height: 100vh;
    height: 100%;
}

.ant-menu-item-selected{
    background-color: #92c5dd !important;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
    border-right: none !important;
}

h1.ant-typography {
    font-size: 10px !important;
    font-weight: 700;
    text-transform: capitalize;
    color: rgba(0, 80, 200, 1) !important;
}